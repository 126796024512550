import axios from "axios";

export default axios.create({
  baseURL: "https://project-tracking-api.azurewebsites.net/",
  // timeout: 1000,
  // headers: new Headers({
  //   'Accept': 'application/json',
  //   'Access-Control-Allow-Origin': '*',
  //   'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
  //   'Access-Control-Request-Method': 'GET, POST, DELETE, PUT, OPTIONS',
  // }),

});