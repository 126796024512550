import React, { useState, useEffect  } from "react";
import AddEmployee from "../../components/Cards/AddEmployee"
import EmployeeList from "../../components/Cards/EmployeeList"
import APIKit from "../../config/APIKit";
// import ReactTable from "react-table";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// components
export default function Employee() {
  const [employeeListing, setemployeeListing] = useState([])

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    getEmployeeList();
  }, []);

  const notifySuccess = () => toast.success("Customer Added Successfully");
  const notifyError = () => toast.error("Error in Customer");

  const getEmployeeList = () => {
    let obj = {
      EmployeeId: null,
    }
      try {
        console.log('data', obj);
        APIKit.post('v1/Employee/EmployeeGet', obj)
          .then(res => {
            let responseData = res.data;
            console.log('data', responseData);
            if(responseData.statusCode == 200){
              setemployeeListing(responseData.data)
              console.log(employeeListing, 'employeeListing')
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
      catch (e) {
        console.log(e);
      }
  }

  const addEmployeeForm = (log) => {
    addEmployeeRequest(log);
  }

  const addEmployeeRequest = (x) => {
    let obj = {
      EmployeeId:null,
      EmployeeName:x.employeeName,
    }
    if(obj){
      try { 
        APIKit.post('v1/Employee/EmployeeAdd', obj)
          .then(res => {
            let responseData = res.data;
            console.log('data', responseData);
            if(responseData.statusCode == 200){
              notifySuccess();
              getEmployeeList();
            }
            else{
              notifyError();
            }
          })
          .catch(error => {
            notifyError();
            // setLoading(false)
            console.log(error);
          });
      }
      catch (e) {
        console.log(e);
      }
    }else{
      console.log("empty")
    }
  }

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <AddEmployee addEmployeeForm={addEmployeeForm} />
        </div>
        <div className="w-full mb-12 xl:mb-0 px-4">
          <EmployeeList   employeeListing={employeeListing} color="light" />
          <ToastContainer/>
        </div>
      </div>
    </>
  );
}
