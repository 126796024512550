import React, { useState } from "react";
import PropTypes from "prop-types";


import {
  Card,
  CardBody,
  Row,
  Col,
  Table,
  Button
} from "reactstrap";
import jsPDF from "jspdf";
import "jspdf-autotable";
// components

export default function ClientReport({ color, clientReportList }) {
  console.log(clientReportList, "props")
  const [clientID, setClientID] = useState('');
  const people = [{ name: "Keanu Reeves", profession: "Actor" },
                    { name: "Lionel Messi", profession: "Football Player" },
                    { name: "Cristiano Ronaldo", profession: "Football Player" },
                    { name: "Jack Nicklaus", profession: "Golf Player" }]

  // const ViewProjectDetail = (item) => {
  //   // setClientID(item.ClientName);
  //   let obj = 'eeeee'
  //   setClientIDProps(obj)
  // }

 const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Client Report";
    const headers = [["Client Name", "Total Hours", "Total Rates"]];

    const data = clientReportList.map(elt=> [elt.ClientName, elt.TotalHrs, elt.TotalRates]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("client_report.pdf")
  }

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              {/* <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
               Client Report
              </h3> */}
              <Button onClick={() => exportPDF()}>Download Report</Button>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* <ul className="clientList">{userList}</ul> */}
          <Row>
            <Col style={{paddingLeft:0, paddingRight:0}}>
              <Card>
                <CardBody>
                  <Table striped responsive>
                  <thead>
                    <tr>
                      <th>Client Name</th>
                      <th>Total Amount of All Project</th>
                      <th>Total Hours</th>
                    </tr>
                    </thead>
                    <tbody>
                      {clientReportList.length ? 
                        clientReportList.map((item, index) => {
                          return (
                              <tr>
                                <td> {item.ClientName} </td>
                                <td> {item.TotalRates} </td>
                                <td> {item.TotalHrs} </td>
                                <td>
                                  {/* <Button outline color="primary" onClick={() => completeTodo(item)}>View</Button> */}
                                </td>
                              </tr>
                          )
                        })
                        : 
                        (<tr>
                          <td>-</td>
                          <td>-</td>
                        </tr>)
                      }
                    </tbody>
                    {/* <Button outline color="primary" onClick={() => completeTodo('2')}>View</Button> */}
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

ClientReport.defaultProps = {
  color: "light",
};

ClientReport.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
