import React, { useState, useEffect  } from "react";
import Select from 'react-select';
import APIKit from "../../config/APIKit";

// components

export default function AddProjectForm({addProjectForm}) {

  const [projectName, setProjectName] = useState("");
  const [clientId, setClientId] = useState(null);
  const [hourlyRate, setHourlyRate] = useState(null);
  const [clientList, setClientList] = useState([])

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    getClientList();
  }, []);

  const getClientList = () => {
    let obj = {
      ClientId: null,
    }
    try {
      APIKit.post('v1/Client/ClientGet', obj)
        .then(res => {
          let responseData = res.data;
          if(responseData.statusCode == 200){
            const arrayList = responseData.data.map(x => ({
              label: x.ClientName,
              value: x.ClientId,
            }));
            console.log(arrayList, "response data")
            setClientList(arrayList)
          }
        })
        .catch(error => {
          // setLoading(false)
          console.log(error);
        });
    }
    catch (e) {
      console.log(e);
    }
  }

  const handleSubmit = (evt) => {
    let obj = {
      projectName: projectName,
      clientId:clientId,
      hourlyRate:parseFloat(hourlyRate)
    }
    if((projectName && clientId) && hourlyRate){
        addProjectForm(obj);
    }
    else{
      alert("Fill Required")
    }
  }

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          {/* <form> */}
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Add Project Detail
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password">
                      Enter Project Name
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      defaultValue=""
                      placeholder="Enter Project Name"
                      onChange={e => setProjectName(e.target.value)}
                    />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password">
                      Client Name
                    </label>
                    {/* <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      defaultValue=""
                      placeholder="Client Name"
                    /> */}
                  <Select
                  className="border-0 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    options={clientList}
                    onChange={opt => setClientId(opt.value)}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password">
                    Hourly Rate
                  </label>
                  <input
                    type="number" 
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Hourly Rate"
                    onChange={e => setHourlyRate(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <button
                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          {/* </form> */}
        </div>
      </div>
    </>
  );
}
