import React from "react";
import PropTypes from "prop-types";

import {
  Card,
  CardBody,
  Row,
  Col,
  Table
} from "reactstrap";

// components

export default function ClientList({ color, customerListing }) {
  console.log(customerListing, "props")
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
               Client List
              </h3>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* <ul className="clientList">{userList}</ul> */}
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Table striped responsive>
                  <thead>
                    <tr>
                      <th>ClientName</th>
                      <th>Notes</th> 
                    </tr>
                    </thead>
                    <tbody>
                      {customerListing.length ? 
                        customerListing.map((item, index) => {
                          return (
                              <tr>
                                <td> {item.ClientName} </td>
                                <td> {item.Notes} </td>
                              </tr>
                          )
                        })
                        : 
                        (<tr>
                          <td>-</td>
                          <td>-</td>
                        </tr>)
                      }
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

ClientList.defaultProps = {
  color: "light",
};

ClientList.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
