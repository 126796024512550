import React, { useState, useEffect  } from "react";
import { Button } from 'reactstrap';
// components
import AddClientForm from "components/Cards/AddClientForm.js";
import ClientList from "components/Cards/ClientList.js";
import APIKit from "../../config/APIKit";
// import ReactTable from "react-table";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Users() {
  
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    getClientList();
  }, []);
  const [customerListing, setCustomerListing] = useState([])
  // const [clientName, setClientName] = useEffect("");
  // const [notes, setNotes] = useEffect("");

  const getClientList = () => {
    let obj = {
      ClientId: null,
    }
      try {
        // setLoading(true)
        console.log('data', obj);
        APIKit.post('v1/Client/ClientGet', obj)
          .then(res => {
            let responseData = res.data;
            console.log('data', responseData);
            if(responseData.statusCode == 200){
              setCustomerListing(responseData.data)
              console.log(customerListing, 'clientList')
            }
          })
          .catch(error => {
            // setLoading(false)
            console.log(error);
          });
      }
      catch (e) {
        console.log(e);
      }
  }

  const notifySuccess = () => toast.success("Customer Added Successfully");
  const notifyError = () => toast.error("Error in Customer");

  const addClientRequest = (x) => {
    let obj = {
      ClientId:null,
      ClientName:x.clientName,
      Notes:x.notes,
      CreatedBy:0,
      ModifiedBy:0,
    }
    if(obj){
      try { 
        APIKit.post('v1/Client/ClientAdd', obj)
          .then(res => {
            let responseData = res.data;
            console.log('data', responseData);
            if(responseData.statusCode == 200){
              notifySuccess();
              getClientList();
            }
            else{
              notifyError();
            }
          })
          .catch(error => {
            notifyError();
            // setLoading(false)
            console.log(error);
          });
      }
      catch (e) {
        console.log(e);
      }
    }else{
      console.log("empty")
    }
  }

  const addClientForm = (log) => {
    addClientRequest(log)
  }

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full mb-12 xl:mb-0 px-4">
          <AddClientForm addEmployeeForm={addClientForm} />
        </div> 
        <div className="w-full mb-12 xl:mb-0 px-4">
          <ClientList customerListing={customerListing} color="light" />
          <ToastContainer />
        </div>
      </div>
    </>
  );
}
