import React from "react";
import AttandanceForm from "../../components/Cards/AttandanceForm"
import APIKit from "../../config/APIKit";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// components
export default function Attendance() {

  const markAttendanceForm = (log) =>{
    console.log(log);
    markAttendanceRequest(log);
  }

  const notifySuccess = () => toast.success("Attendance Mark Successfully");
  const notifyError = () => toast.error("Error in Attendance");

  const markAttendanceRequest = (x) => {
    let obj = {
        EmployeeId :x.employeeId,
        ProjectId : x.projecttId,
        EmployeeHrs :x.hourlyRate,
        CreatedBy :1
    }
    if(obj){
      try { 
        APIKit.post('v1/Employee/EmployeeAttendanceAdd', obj)
          .then(res => {
            let responseData = res.data;
            console.log('data', responseData);
            if(responseData.statusCode == 200){
              notifySuccess();
            }
            else{
              notifyError();
            }
          })
          .catch(error => {
            notifyError();
            // setLoading(false)
            console.log(error);
          });
      }
      catch (e) {
        console.log(e);
      }
    }else{
      console.log("empty")
    }
  }

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <AttandanceForm markAttendanceForm={markAttendanceForm}/>
          <ToastContainer />
        </div>
      </div>
    </>
  );
}
