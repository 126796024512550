
import React, { useState, useEffect  } from "react";
import AddProjectForm from "components/Cards/AddProjectForm";
import ProjectList from "components/Cards/ProjectList";
import APIKit from "../../config/APIKit";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// components
export default function Project() {

  const [projectListing, setprojectListing] = useState([])

  useEffect(() => {
    // POST request using axios inside useEffect React hook
      getProjectList();
  }, []);

  const getProjectList = () => {
    let obj = {
      ProjectId: null,
    }
      try {
        // setLoading(true)
        APIKit.post('v1/Project/ProjectGet', obj)
          .then(res => {
            let responseData = res.data;
            if(responseData.statusCode == 200){
              setprojectListing(responseData.data)
              // console.log(projectListing, 'clientList')
            }
          })
          .catch(error => {
            // setLoading(false)
            console.log(error);
          });
      }
      catch (e) {
        console.log(e);
      }
  }

  const notifySuccess = () => toast.success("Project Added Successfully");
  const notifyError = () => toast.error("Error in Project");

  const addProjectForm = (log) => {
    addProjectRequest(log);
  }
  const addProjectRequest = (idx) => {
    let obj = {
      ProjectId :0,
      ProjectName : idx.projectName,
      ClientId  : idx.clientId,
      HourlyRate : idx.hourlyRate,
      CreatedBy  : 1,
      ModifiedBy :1
    }
    if(obj){
      console.log(obj)
      try { 
        APIKit.post('v1/Project/ProjectAdd', obj)
          .then(res => {
            let responseData = res.data;
            console.log('data', responseData);
            if(responseData.statusCode == 200){
              notifySuccess();
              getProjectList();
            }
            else{
              notifyError();
            }
          })
          .catch(error => {
            notifyError();
            console.log(error);
          });
      }
      catch (e) {
        console.log(e);
      }
    }else{
      console.log("empty")
    }
  }


  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          <AddProjectForm addProjectForm={addProjectForm} />
        </div>
        <div className="w-full mb-12 xl:mb-0 px-4">
          <ProjectList projectListing={projectListing} color="light" />
          <ToastContainer />
        </div>
      </div>
    </>
  );
}
