import React, { useState, useEffect } from "react";
import ClientReport from "components/Cards/ClientReports";
import APIKit from "../../config/APIKit";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import ProjectReport from '../../components/Cards/ProjectReport';
import Select from 'react-select';
import {
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import classnames from 'classnames';

// components
export default function Report() {
  const [clientReportList, setClientReportList] = useState([]);
  // const [modal, setModal] = useState(false);
  const [projectReportList, setProjectReportList] = useState([]);
  const [projectID, setProjectId] = useState(null);
  const [activeTab, setActiveTab] = useState('1');

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    getClientReport();
    // getProjectReport();
  }, []);

  // const toggle = () => setModal(!modal);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const getClientReport = () => {
    // let obj = {
    //   ProjectId: null,
    // }
    try {
      // setLoading(true)
      APIKit.post('v1/Project/ClientReport')
        .then(res => {
          let responseData = res.data;
          if (responseData.statusCode == 200) {
            setClientReportList(responseData.data)
          }
        })
        .catch(error => {
          // setLoading(false)
          console.log(error);
        });
    }
    catch (e) {
      console.log(e);
    }
  }

  const getProjectReport = () => {
    let obj = {
      ClientId: null,
    }
    try {
      // setLoading(true)
      APIKit.post('v1/Project/GetProjectReport', obj)
        .then(res => {
          let responseData = res.data;
          if (responseData.statusCode == 200) {
              console.log(responseData, "sdddd")
              setProjectReportList(responseData.data)
          }
        })
        .catch(error => {
          // setLoading(false)
          console.log(error);
        });
    }
    catch (e) {
      console.log(e);
    }
  }

  // const getProjectReport = () => {
  //   let obj = {
  //     ClientId: 1,
  //   }
  //   try {
  //     APIKit.post('v1/Project/GetProjectReport', obj)
  //       .then(res => {
  //         let responseData = res.data;
  //         console.log(responseData, "Project Remote")
  //         if (responseData.statusCode == 200) {
  //           const arrayList = responseData.data.map(x => ({
  //             label: x.ProjectName,
  //             value: 2,
  //           }));
  //           console.log(arrayList, "response data")
  //           setProjectReportList(arrayList)
  //         }
  //       })
  //       .catch(error => {
  //         // setLoading(false)
  //         console.log(error);
  //       });
  //   }
  //   catch (e) {
  //     console.log(e);
  //   }
  // }

  // const setClientIDProps = (obj) => {
  //   console.log(obj)
  // }

  // const completeTodo = index => {
  //   console.log(index, "index");
  // };


  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <ClientReport clientReportList={clientReportList} />
        </div>
        <div className="w-full lg:w-12/12 px-4">
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => { toggle('1'); }} >
                  Client Report
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => { toggle('2'); }}>
                  Project Report
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => { toggle('3'); }}>
                  Attendance Report
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <ClientReport clientReportList={clientReportList} />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="6">
                    {/* <ProjectReport projectReportList={projectReportList} /> */}
                  </Col>
                  <Col sm="6">
                    {/* <Card body>
                      <CardTitle>Special Title Treatment</CardTitle>
                      <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                      <Button>Go somewhere</Button>
                    </Card> */}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col sm="12">
                    {/* <ProjectReport /> */}
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
          {/* Tab Layout */}

        </div>
      </div>
    </>
  );
}
