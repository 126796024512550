import React, { useState, useEffect  } from "react";
import Select from 'react-select';
import APIKit from "../../config/APIKit";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// components
export default function AttandanceForm({markAttendanceForm}) {

  const [employeeId, setEmployeeId] = useState(null);
  const [projecttId, setProjectId] = useState(null);
  const [hourlyRate, setHourlyRate] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [projectListing, setprojectListing] = useState([])
  const [employeeListing, setemployeeListing] = useState([])

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    getProjectList();
    getEmployeeList();
  }, []);

  const getProjectList = () => {
    let obj = {
      ProjectId: null,
    }
      try {
        // setLoading(true)
        APIKit.post('v1/Project/ProjectGet', obj)
          .then(res => {
            let responseData = res.data;
            if(responseData.statusCode == 200){
              const arrayList = responseData.data.map(x => ({
                label: x.ProjectName,
                value: x.ProjectId,
              }));
              setprojectListing(arrayList)
            }
          })
          .catch(error => {
            // setLoading(false)
            console.log(error);
          });
      }
      catch (e) {
        console.log(e);
      }
  }

  const getEmployeeList = () => {
    let obj = {
      EmployeeId: null,
    }
      try {
        console.log('data', obj);
        APIKit.post('v1/Employee/EmployeeGet', obj)
          .then(res => {
            let responseData = res.data;
            console.log('data', responseData);
            if(responseData.statusCode == 200){
              const arrayList = responseData.data.map(x => ({
                label: x.EmployeeName,
                value: x.EmployeeId,
              }));
              setemployeeListing(arrayList)
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
      catch (e) {
        console.log(e);
      }
  }

  const handleSubmit = (evt) => {
    let obj = {
      employeeId: employeeId,
      projecttId:projecttId,
      hourlyRate:parseFloat(hourlyRate)
    }
    if((employeeId && projecttId) && hourlyRate){
        markAttendanceForm(obj);
    }
    else{
      alert("Fill Required")
    }
  }


  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
      <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          {/* <form  onSubmit={handleSubmit}> */}
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Mark Attendance Form
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password">
                    Select Date
                  </label>
                  {/* <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue=""
                    placeholder="Client Name"
                  /> */}
                  <DatePicker selected={startDate} onChange={(date) => setStartDate(date)}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password">
                    Employee Working Hours
                  </label>
                  <input
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue=""
                    placeholder="Working Hour"
                    onChange={e => setHourlyRate(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password">
                    Select Employee Name
                  </label>
                  <Select
                    className="border-0 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    options={employeeListing}
                    onChange={opt => setEmployeeId(opt.value)}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password">
                    Select Project Name
                  </label>
                  <Select
                    className="border-0 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    options={projectListing}
                    onChange={opt => setProjectId(opt.value)}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <button
                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Mark Attendacne
                  </button>
                </div>
              </div>
            </div>
          {/* </form> */}
        </div>
      </div>
    </>
  );
}
