import React, { useState, useEffect  } from "react";
import { Link } from "react-router-dom";
import APIKit from "config/APIKit";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Loader from "react-loader-spinner";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default function Login() {
  const history = useHistory();

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState(null)

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
  // POST request using axios inside useEffect React hook
  // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

const notifySuccess = () => toast.success("Login Successfully");
const notifyError = () => toast.error("Invalid Credentails");


  const handleChange = () => {
    let obj = {
      Email: email,
      Password: password,
    }
    if(obj.Email != "" && obj.Password != null){
      try {
        setLoading(true)
        console.log('data', obj);
        APIKit.post('v1/User/SignIn', obj)
          .then(res => {
            let responseData = res.data;
            console.log('data', responseData);
            if(responseData.statusCode == 200){
              notifySuccess();
              let obj = responseData.data[0]
              localStorage.setItem('@user',JSON.stringify(obj));
              // const datalist = localStorage.getItem('@user');
              history.push("/admin/users");
            }
            if(responseData.statusCode == 192){
              setLoading(false)
              notifyError();
              console.log(responseData.data, '192')
            }
            else{
              notifyError();
            }
          })
          .catch(error => {
            notifyError();
            setLoading(false)
            console.log(error);
          });
      }
      catch (e) {
        console.log(e);
      }
    }else{
      console.log("empty")
    }
  }

  const routeSignup = () => {
    console.log(history)
    history.push("/auth/register");
    // this.props.history.push("/auth/register")
    // <Link to="/admin/users" className="text-blueGray-200">

  }

  return (
    <>
      <div className="container mx-auto px-4 h-full loginDashboard">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Sign in with
                  </h6>
                </div>
                <div className="btn-wrapper text-center">
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-blueGray-400 text-center mb-3 font-bold">
                  {/* <small>Or sign in with credentials</small> */}
                        {/* <Loader
                        type="Rings"
                        color=""
                        height={100}
                        width={100}
                        className="spiner"
                        // timeout={3000} //3 secs
                      /> */}
                </div>
                <form>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      onChange={e => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      onChange={e => setPassword(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-600">
                        Remember me
                      </span>

                    </label>
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                      onClick={handleChange}
                     >
                      Sign In
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
              <ToastContainer />
                {/* <a
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  className="text-blueGray-200"
                >
                  <small>Forgot password?</small>
                </a> */}
              </div>
              <div className="w-1/2 text-right">
                {/* <Link to="/auth/register" className="text-blueGray-200"> */}
                  <small style={{
                        fontSize: 15,
                        color: "#fff",
                        cursor:"pointer"
                  }}
                  onClick={routeSignup}
                  >Create new account</small>
                {/* </Link> */}
              </div>
              {/* <button onClick={notify}>Notify!</button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
